import React from 'react';
import data from './cgi.json'
import Highlighter from "react-highlight-words";
import { debounce } from "lodash"

import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import SegmentIcon from '@mui/icons-material/Segment';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import FormatIndentDecreaseIcon from '@mui/icons-material/FormatIndentDecrease';
import TitleIcon from '@mui/icons-material/Title';
import FolderIcon from '@mui/icons-material/Folder';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';


function App() {

    const [searchValue, setSearchValue] = React.useState("");

    const [livres, setLivres] = React.useState([]);

    const [displayArticle, setDisplayArticle] = React.useState(0);
    const [displayLivre, setDisplayLivre] = React.useState("");
    const [displayPartie, setDisplayPartie] = React.useState("");
    const [displayTitre, setDisplayTitre] = React.useState("");
    const [displaySubtitle, setDisplaySubtitle] = React.useState("");
    

    const debouncedSearch = React.useRef(
      debounce(async (criteria) => {
        setSearchValue(criteria);
      }, 300)
    ).current;

    React.useEffect(() =>{

      data.map(entry => { livres.push(entry.Livre)})

      setLivres(livres.reduce((unique, item) => {return unique.includes(item) ? unique : [...unique, item]}, []));
      setDisplayLivre(livres[0].Livre)

      return () => {
        debouncedSearch.cancel();
      };

    }, [debouncedSearch])



    const DisplayArticles = (livre, partie, titre, subTitre, chapitre, section, paragraphe) => {


      let titres_livre = [];
      data.filter((row) => {if(row.Livre === livre 
        && row.Partie === partie 
        && row.Titre === titre 
        && row["Sous-titre"] === subTitre
        && row.Chapitre === chapitre
        && row.Section === section
        && row.Paragraphe === paragraphe) return row } ).map(entry => { titres_livre.push(entry)});

      return titres_livre.reduce((unique, item) => {return unique.includes(item) ? unique : [...unique, item]}, []).map((article) => {
          return (
            <>
              {article.Article_structured.Title !== "" && displayPartie === partie && displayLivre === livre && displayTitre === titre && 
              <div id={article.ID + "-article-title"} className="bg-white text-black align-center items-center ml-4">
                  <h1 className={`hover:bg-blue-500 cursor-pointer shadow-xl bg-white my-2 p-2 z-50 font-bold text-white ${displayArticle ===  article.ID ? `bg-blue-500` : `bg-blue-400`}` } 
                      onClick={(e) =>  {
                          if(e.target.parentNode.id === article.ID + "-article-title" && displayArticle !== null){
                            setDisplayArticle(null);
                          }
                          else
                          setDisplayArticle(article.ID); 
                        }
                      }>
                    {displayArticle ===  article.ID ? <KeyboardArrowDownRoundedIcon color="white"/> : <KeyboardArrowRightRoundedIcon color="white"/>}
                    {article.Article_structured.Title}
                  </h1>
                {displayArticle ===  article.ID && <p className={"shadow-xl bg-white my-2 p-2 z-50 whitespace-pre-line text-gray-800"}>
                  {article.Article_structured.Content}
                </p>}
              </div>}
            </>
          )
      });
    }

    const DisplayParagraphes = (livre, partie, titre, subTitre, chapitre, section) => {
      let titres_livre = [];
      data.filter((row) => {if(row.Livre === livre 
        && row.Partie === partie 
        && row.Titre === titre 
        && row["Sous-titre"] === subTitre
        && row.Chapitre === chapitre
        && row.Section === section) return row } ).map(entry => { titres_livre.push(entry.Paragraphe)});

      return titres_livre.reduce((unique, item) => {return unique.includes(item) ? unique : [...unique, item]}, []).map((paragraphe) => {

          return (
            <>
              {paragraphe !== "" && displayPartie === partie && displayLivre === livre && displayTitre === titre &&
              <h1 className="bg-blue-400 text-white p-2 flex flex-row align-center items-center ml-4">
                <FormatIndentDecreaseIcon color="white" sx={{ fontSize: 18 }} />
                <span className="ml-2">{paragraphe}</span>
              </h1>}
              {DisplayArticles(livre, partie, titre, subTitre, chapitre, section, paragraphe)}
            </>
          )
      });
    }

    const DisplaySections = (livre, partie, titre, subTitre, chapitre) => {
      let titres_livre = [];
      data.filter((row) => {if(row.Livre === livre 
        && row.Partie === partie 
        && row.Titre === titre 
        && row["Sous-titre"] === subTitre
        && row.Chapitre === chapitre) return row } ).map(entry => { titres_livre.push(entry.Section)});

      return titres_livre.reduce((unique, item) => {return unique.includes(item) ? unique : [...unique, item]}, []).map((section) => {

          return (
            <>
              {section !== "" && displayPartie === partie && displayLivre === livre && displayTitre === titre &&
              <h1 className="bg-blue-400 text-white  my-2 p-2 flex flex-row align-center items-center ml-4">
                <SegmentIcon color="white" sx={{ fontSize: 18 }} />
                <span className="ml-2">{section}</span>
              </h1>}
              {DisplayParagraphes(livre, partie, titre, subTitre, chapitre, section)}
            </>
          )
      });
    }


    const DisplayChapitres = (livre, partie, titre, subTitre) => {
      let titres_livre = [];
      data.filter((row) => {if(row.Livre === livre && row.Partie === partie && row.Titre === titre && row["Sous-titre"] === subTitre) return row } ).map(entry => { titres_livre.push(entry.Chapitre)});

      return titres_livre.reduce((unique, item) => {return unique.includes(item) ? unique : [...unique, item]}, []).map((chapitre) => {

          return (
            <>
              {chapitre !== "" && displayPartie === partie && displayLivre === livre && displayTitre === titre &&
              <h1 className="bg-blue-400 text-white my-2 p-2  flex flex-row align-center items-center">
                <Inventory2Icon color="white" sx={{ fontSize: 18 }} />
                <span className="ml-2">{chapitre}</span>
              </h1>}
              {DisplaySections(livre, partie, titre, subTitre, chapitre)}
            </>
          )
      });
    }

    const DisplaySubTitles = (livre, partie, titre) => {
      let titres_livre = [];
      data.filter((row) => {if(row.Livre === livre && row.Partie === partie && row.Titre === titre) return row } ).map(entry => { titres_livre.push(entry["Sous-titre"])});

      return titres_livre.reduce((unique, item) => {return unique.includes(item) ? unique : [...unique, item]}, []).map((subTitre) => {

          return (
            <>
              {subTitre !== "" && displayPartie === partie && displayLivre === livre && displayTitre === titre &&
                <h1 className="bg-blue-400 text-white m-2 p-2 flex flex-row align-center items-center"
                  onClick={() => { 
                    if(displaySubtitle === subTitre) setDisplayTitre("")
                    else setDisplaySubtitle(subTitre);
                  }}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 4v12l-4-2-4 2V4M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z" />
                  </svg>
                  <span className="ml-2">{subTitre}</span>
                </h1>
              }
              {DisplayChapitres(livre, partie, titre, subTitre)}
            </>
          )

      });
    }

    const DisplayTitles = (livre, partie) => {
      let titres_livre = [];
      data.filter((row) => {if(row.Livre === livre && row.Partie === partie) return row } ).map(entry => { titres_livre.push(entry.Titre)});

      return titres_livre.reduce((unique, item) => {return unique.includes(item) ? unique : [...unique, item]}, []).map((titre) => {

          return (
            <>
              {titre !== "" &&  displayLivre === livre  && displayPartie === partie && 
                <h1 className="cursor-pointer bg-blue-400 text-white my-2 p-2 flex flex-row align-center items-center"
                onClick={() => {
                  if(displayTitre === titre) setDisplayTitre("");
                  else setDisplayTitre(titre);
                }}
                >
                  <TitleIcon color="white" sx={{ fontSize: 18 }}   />
                  <span className="ml-2">{titre}</span>
                </h1>
              }
              {DisplaySubTitles(livre, partie, titre)}
            </>
          )

      });
    }


    const DisplayParties = (livre) => {
      let parties_livre = [];
      data.filter((row) => {if(row.Livre === livre) return row } ).map(entry => { parties_livre.push(entry.Partie)});

      return parties_livre.reduce((unique, item) => {return unique.includes(item) ? unique : [...unique, item]}, []).map((partie) => {

          return (
            <>
              {partie !== "" && displayLivre === livre && 
                <h1 className="cursor-pointer bg-blue-400 text-white my-2 p-2 flex flex-row align-center items-center"
                  onClick={() => { 
                    if(displayPartie === partie) setDisplayPartie("");
                    else setDisplayPartie(partie); 
                  }}
                >
                  <FolderIcon  color="white" sx={{ fontSize: 18 }} />
                  <span className="ml-2">{partie}</span>
                </h1>
              }
              {DisplayTitles(livre, partie)}
            </>
          )

      });
    }



    const DisplayLivres = livres.map((livre) => { return (
      <>
        { 
          <h1 className="cursor-pointer hover:bg-blue-300 bg-blue-500 text-white my-2 p-2 flex flex-row align-center items-center"
          // onClick={() => {setDisplayParties(!displayParties); setDisplayTitres(!displayTitres); setDisplaySubTitres(!displaySubTitres); setDisplayChapitres(!displayChapitres); setDisplaySections(!displaySections); setDisplayParagraphes(!displayParagraphes); setDisplayArticles(!displayArticles);}}
          onClick={() => { 
            if(displayLivre === livre) setDisplayLivre("");
            else setDisplayLivre(livre);
          }}
          >
            <ImportContactsIcon  color="white" sx={{ fontSize: 18 }} />
            <span className="ml-2">{livre}</span>
          </h1>
        }
        {DisplayParties(livre)}
      </>
    )})

    const SearchResult = data.filter((entry) => {
      if(entry.Titre.includes(searchValue) || 
        entry.Chapitre.includes(searchValue) || 
        entry.Article_structured.Content.includes(searchValue)|| 
        entry.Article_structured.Title.includes(searchValue)) return entry}).map((entry) => {
        return (
          <>
          <div className="flex flex-row flex-wrap justify-start">
            {entry.Livre !== "" && <h1 className="cursor-pointer bg-blue-500 text-white my-2 p-2 flex flex-row align-center items-center text-2sm">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
              </svg>
              <span className="ml-2">{entry.Livre}</span>
            </h1>}
            {entry.Partie !== "" && <h1 className="cursor-pointer bg-blue-400 text-white my-2 ml-2 p-2 flex flex-row align-center items-center text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 6a2 2 0 012-2h5l2 2h5a2 2 0 012 2v6a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" />
                <path stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 11h4m-2-2v4" />
              </svg>
              <span className="ml-2">{entry.Partie}</span>
            </h1>}
            {entry.Chapitre !== "" && <h1 className="cursor-pointer bg-blue-400 text-white my-2 ml-2 p-2 flex flex-row align-center items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                </svg>
              <span className="ml-2">{entry.Chapitre}</span>
            </h1>}
            {entry.Section !== "" && <h1 className="cursor-pointer bg-blue-400 text-white my-2 ml-2 p-2 flex flex-row align-center items-center text-sm">
                <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4" />
                </svg>
              <span className="ml-2">{entry.Section}</span>
            </h1>}
            {entry.Titre !== "" && <h1 className="cursor-pointer bg-blue-400 text-white my-2 ml-2 p-2 flex flex-row align-center items-center text-sm">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z" />
              </svg>
              <span className="ml-2">{entry.Titre}</span>
            </h1>}
          </div>
          <h1 className="bg-blue-500 shadow-xl bg-white my-2 p-2 z-50 font-bold text-white">
            {entry.Article_structured.Title}
          </h1>
          <p className="shadow-xl bg-white p-2 z-50 whitespace-pre-line">
            <Highlighter
              highlightClassName="YourHighlightClass"
              searchWords={[searchValue]}
              autoEscape={true}
              textToHighlight={entry.Article_structured.Content}
            />
          </p>
          </>
        )
    })
  
      return (
          <div className="flex-auto lg:pt-10">
            <div className="pt-0 lg:px-4 xl:px-20 md:flex md:flex-row justify-between py-4 bg-white sticky top-0">
              <a href="/"><h1 className="text-2xl p-4 text-white bg-blue-500">Code général des impôts marocain</h1></a>
              <div class="bg-white flex items-center border">
                <input class="rounded-l-full w-full py-4 px-6 text-gray-700 leading-tight focus:outline-none" id="search" type="text" placeholder="Chercher un article par thème"
                  onChange={(e) => {debouncedSearch(e.target.value); }}
                />
                <button class="bg-blue-500 text-white p-4 lg:p-2 lg:m-2 hover:bg-blue-400 focus:outline-none flex items-center justify-center">
                {searchValue !== "" ? 
                  <CloseIcon onClick={() => setSearchValue("")} />:
                  <SearchIcon />
                  }
                </button>
              </div>
            </div>
            <div className="lg:px-4 xl:px-20">
              {searchValue === "" ? DisplayLivres : (SearchResult.length === 0 ? <div className="bg-white m-2 p-2 shadow-xl">Aucun article ne correspond à cette recherche</div> : SearchResult)}
            </div>
            <div className="lg:px-4 xl:px-20 p-2 text-blue-500">
              <p className="break-all"><strong>Source :</strong> <a href="https://www.finances.gov.ma/Publication/dgi/2021/cgi2021-fr.pdf">https://www.finances.gov.ma/Publication/dgi/2021/cgi2021-fr.pdf</a></p>
            </div>
            <div className="bg-blue-500 cursor-pointer lg:w-12 lg:h-12 text-white fixed bottom-0 right-0 bottom-20" 
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
              });}}>
              <KeyboardArrowUpRoundedIcon color="white" sx={{fontSize: 50}} />
            </div>
          </div>
      );

}

export default App;
